.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  font-weight: 600;
  border-radius: 5px;
}
.ant-input:hover,
.ant-input:focus,
.ant-input:active {
  border-color: #474040;
  box-shadow: none;
}
#inputCustom .ant-input,
#inputCustom .ant-picker {
  height: 50px !important;
  border-radius: 5px !important;
}

#inputCustom .ant-select-selector {
  /* cursor: text; */
  height: 50px;
  /* padding: 13px 12px; */
  padding: 10px 11px;
  font-size: 14px;
  border-radius: 5px;
  /* line-height: 1.5715; */
}

#inputCustom .ant-select-selection-search {
  margin-top: 10px;
  /* padding: 4px 11px; */
  /* font-size: 14px; */
  /* line-height: 1.5715; */
}

#inputCustom .ant-select:not(.ant-select-disabled):active .ant-select-selector,
#inputCustom .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
#inputCustom .ant-select:not(.ant-select-disabled):focus .ant-select-selector,
#inputCustom
  .ant-select-focused:not(.ant-select-disabled)
  .ant-select-selector {
  border-color: #474040;
  box-shadow: none;
}

#inputCustomSmall .ant-input {
  height: 36px;
  border-radius: 5px;
}

#inputCustomSmall .ant-select-selector {
  /* cursor: text; */
  height: 36px;
  padding: 5px 11px;
  font-size: 14px;
  border-radius: 5px;
  /* line-height: 1.5715; */
}

#inputCustomSmall .ant-select-selection-search {
  margin-top: 0px;
  padding: 4px 0px;
  /* font-size: 14px; */
  /* line-height: 1.5715; */
}

#inputCustomSmall
  .ant-select:not(.ant-select-disabled):active
  .ant-select-selector,
#inputCustomSmall
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector,
#inputCustomSmall
  .ant-select:not(.ant-select-disabled):focus
  .ant-select-selector,
#inputCustomSmall
  .ant-select-focused:not(.ant-select-disabled)
  .ant-select-selector {
  border-color: #474040;
  box-shadow: none;
}
