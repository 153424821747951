#setMinheight .ant-table-tbody {
  min-height: 500px;
}
#tagDate .ant-tag {
  width: 110px !important;
  border-radius: 6px !important;
  margin-bottom: 5px !important;
}

.ant-table {
  font-size: 0.9375rem !important;
  color: rgb(51, 51, 51) !important;
}
.ant-table-body {
  overflow-x: auto !important;
}
.ant-table-thead > tr > th {
  background-color: rgba(255, 255, 255, 0.85) !important;
  border-bottom: 2px solid #dee2e6 !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td {
  border-right: 0px solid #f0f0f0 !important;
  border-top: 1px solid #dee2e6 !important;
}
/* .ant-pagination-options-size-changer.ant-select {
    display: none !important;
} */
td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0) !important;
}
.ant-table.ant-table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.ant-table.ant-table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
/* .ant-table.ant-table td h2 a {
  color: #333;
} */

.ant-table.ant-table td h2 a {
  color:   #00A79D;
}
.ant-table.ant-table td h2 a:hover{
  color:  #1C75BC;
}
.ant-table.ant-table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}
.ant-table-thead > tr,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0px 2px !important;
}
.ant-table.ant-table tr {
  box-shadow: 0 0 3px #e5e5e5;
}
.ant-pagination {
  margin-top: 20px !important;
  text-align: end;
}
.ant-pagination-prev {
  border: 1px solid #dee2e6;
  padding: 1px 10px;
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.ant-pagination-next {
  border: 1px solid #dee2e6;
  padding: 1px 10px;
  background-color: #fff;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: rgb(108, 117, 125) !important;
  font-family: "CircularStd", sans-serif !important;
  font-size: 15px !important;
  height: 36px !important;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0px !important;
}
.ant-pagination-item {
  margin-right: 0px !important;
  padding: 1px 0px !important;
  height: 36px !important;
}

.ant-pagination-total-text {
  float: left;
  color: rgb(51, 51, 51) !important;
  font-family: "CircularStd", sans-serif !important;
  font-size: 15px !important;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: rgb(0, 197, 251) !important;
  border-color: rgb(0, 197, 251) !important;
  color: rgb(255, 255, 255) !important;
  z-index: 1 !important;
}

.ant-table-pagination-right {
  float: none !important;
}

#selectCustom .ant-select-selector {
  cursor: text;
  height: 44px;
  padding: 6px 12px;
  border-radius: 0.25rem;
}

#selectCustom .ant-select-selection-search {
  margin-top: 6px;
}

#selectCustom .ant-select:not(.ant-select-disabled):active .ant-select-selector,
#selectCustom .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
#selectCustom .ant-select:not(.ant-select-disabled):focus .ant-select-selector,
#selectCustom
  .ant-select-focused:not(.ant-select-disabled)
  .ant-select-selector {
  border-color: #e3e3e3;
  box-shadow: none;
}
.ant-picker-focused,
.ant-picker:focus,
.ant-picker:hover,
.ant-picker:active {
  border-color: #e3e3e3;
  box-shadow: none;
}

#contactForm .ant-picker {
  height: 44px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
#contactForm .ant-picker-disabled {
  background: #e9ecef;
  border-color: #e3e3e3;
}

:where(.css-dev-only-do-not-override-42nv3w).ant-select .ant-select-arrow{
  top:68%;
}

#custom-form-input .ant-input{
  font-size: 16px;
  padding: 6px 6px 10px;
}
#custom-form-input .ant-select-selection-item{
  color: #333;
  min-width: 78px;
}
#custom-form-input .ant-input-group-addon{
  
  background-color: #f0f0f0;
}

#custom-form-input :where(.css-dev-only-do-not-override-42nv3w).ant-select .ant-select-arrow{
  top:55%;
  font-weight: bold;
}
.mini-sidebar ul .profile-img-wrap{
  margin-top: 25px;
  margin-bottom: 25px;
}
.mini-sidebar ul .profile-info{
  display: none; 
}
.mini-sidebar ul .profile-img-wrap, .mini-sidebar ul .profile-img-wrap img{
  width: 42px;
  height: 42px;
  transition: width 0.5s ease, height 0.5s ease;
  /* transition: transform 0.5s ease; */
}
.mini-sidebar.expand-menu ul .profile-img-wrap, .mini-sidebar.expand-menu ul .profile-img-wrap img{
  width: 120px;
  height: 120px;
  transition: width 0.5s ease, height 0.5s ease;
  
}

.mini-sidebar.expand-menu ul .profile-info{
  display: block; 
}
body .sidebar .profile-info a {
  display: inline-block;
  color: #bbc4cc;
}
[data-sidebar=light] .sidebar .profile-info a {
  display: inline-block;
  color: #333333;
}
[data-layout-mode=dark] body .sidebar .profile-info a {
  display: inline-block;
  color: #bbc4cc;
}

#contactFormMulti :where(.css-dev-only-do-not-override-42nv3w).ant-select-single{
  width: 100%;
  height: 43px;
}
:where(.css-dev-only-do-not-override-j9bb5n).ant-checkbox-checked .ant-checkbox-inner{
  background-color: #1C75BC !important;
  border-color: #1C75BC !important;
}
:where(.css-dev-only-do-not-override-j9bb5n).ant-checkbox-wrapper{
  font-weight: 500 !important;
}

.course-details-left figure { margin-bottom: 10px; }
.course-details-left figure img { border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
.course-details-right figure { position: relative; }
.course-details-right figure img { width: 100%; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }