// .sidebar .sidebar-menu ul li:hover .am-tree:before,
// .two-col-bar .sidebar-menu ul li:hover .am-tree:before {
//     content: "";
//     display: block;
//     background: url(../img/icon-tree-active.svg) center/cover no-repeat; /* Change background to active icon */
//     width: 24px;
//     height: 24px;
//     background-position: center;
//     background-size: cover;
// }

.am-tree:before {
    content: "";
    display: block;
    background: url(../img/icon-tree-active.svg) center/cover no-repeat; /* Default background icon */
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
    transition: filter 0.3s ease; /* Add transition for smooth color change */
    filter: invert(25%);
}

/* Change color on hover */
.sidebar .sidebar-menu ul li:hover .am-tree:before,
.two-col-bar .sidebar-menu ul li:hover .am-tree:before {
    filter: invert(0%); /* Invert the colors by 50% on hover */
}

[data-sidebar=light] .sidebar .sidebar-menu ul li:hover .am-tree:before,
[data-sidebar=light] .two-col-bar .sidebar-menu ul li:hover .am-tree:before {
    filter: invert(80%); /* Invert the colors by 50% on hover */
}