/*** default values ***/
$__full__width:100%;
$__half__width:50%;
$__full_height_window:100vh;
$__full__height:100%;
$__half__height:50%;
$__left:left;
$__right:right;
$__italic:italic;
$__center:center;
$__block:block;
$__inline__block:inline-block;
$__inline:inline;
$__inline__table:inline-table;
$__table:table;
$__flex-start:flex-start; 
$__flex:flex;
$__table__cell:table-cell;
$__table-row:table-row;
$__stretch:stretch;
$__webkit__flex:-webkit-flex;
$__none:none;
$__row:row;
$__pre-wrap:pre-wrap;
$__end:end;
$__table-cell:table-cell;
$__column:column;
$__wrap:wrap;
$__border-box:border-box;
$__vertical:vertical;
$__break-all:break-all;
$__table__row:table-row;
$__absolute:absolute;
$__relative:relative;
$__static:static;
$__sticky:sticky;
$__fixed:fixed;
$__move:move;
$__border__box:border-box;
$__unset:unset;
$__uppercase:uppercase;
$__lowercase:lowercase;
$__capitalize:capitalize;
$__inherit:inherit;
$__initial:initial;
$__auto:auto;
$__normal:normal;
$__margin__auto:0 auto;
$__null:null;
$__bottom:bottom;
$__middle:middle;
$__top:top;
$__transparent:transparent;
$__no_repeat:no-repeat;
$__cover:cover;
$__pointer:pointer;
$__not-allowed:not-allowed;
$__hidden:hidden;
$__show:show;
$__visible:visible;
$__both:both;
$__ellipsis:ellipsis;
$__nowrap:nowrap;
$__inline__flex:inline-flex;
$__underline:underline;

/***** custom values *****/
$__grid__padding__left:15px;
$__grid__padding__right:15px;
$__container__width:1140px;


/***** Grid Values *****/
$__col__1:8.33333333%;
$__col__2:16.66666667%;
$__col__3:25%;
$__col__4:33.33333333%;
$__col__5:41.66666667%;
$__col__6:50%;
$__col__7:58.33333333%;
$__col__8:66.66666667%;
$__col__9:75%;
$__col__10:83.33333333%;
$__col__11:91.66666667%;
$__col__12:100%;


