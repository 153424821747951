.ant-upload-list-picture-card{
    .ant-upload.ant-upload-select, .ant-upload-list-item-container{
        width: 100% !important;
        height: 200px !important;
    }
    .anticon {
        svg {
            display: inline-block !important;
        }
    }
}